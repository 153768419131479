import ScriptModel from "../_utils/script-model";
import { getOverseaReportConfig } from "../../biz-meta";
import { isSimpleChinese } from "../../langs";
import { isMasterEnv } from "../../constants";

/**
 * 海外报告统计
 * @description 用于海外埋点上报
 */
export const overseaReport = new ScriptModel({
    condition: !isSimpleChinese() && isMasterEnv,
    script: 'https://static-web.amazingseasuncdn.com/xassets/lib/oversea-report/0.1.x/index.min.js',
    resolve: () => {
        const overseaReportConfig = getOverseaReportConfig();
        if (overseaReportConfig) {
            const overseaReport = (window as any).OverseaReport.getInstance(overseaReportConfig);
            overseaReport.trackPageLoad();
            overseaReport.trackPageLeave();
        }
    }
});