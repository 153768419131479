import ScriptModel from "./script-model";
import {loadScript} from "../../utils/load-script";
import {log} from "../../logs";

/**
 * 注入脚本
 * @param scriptModels - 脚本模型
 * @returns 所有脚本注入的 Promise
 */
export async function injectScripts(scriptModels: Record<string, ScriptModel>): Promise<void[]> {
    // 获取可用的脚本模型 key
    const availableKeys = Object.keys(scriptModels).filter(key => {
        const {condition} = scriptModels[key];
        return condition;
    });

    log.debug('可用的脚本模型 keys:', availableKeys);

    // 创建注入任务
    const promises = availableKeys.map(key => {
        const {script, resolve, reject, prepare, attrs} = scriptModels[key];
        prepare();
        let promise;

        log.debug(`准备注入脚本: ${key}`);

        // 如果脚本是数组，则逐个注入
        if (Array.isArray(script)) {
            promise = (async () => {
                for (let i = 0; i < script.length; i++) {
                    const scriptSrc = script[i];
                    log.debug(`注入数组脚本 ${i + 1}/${script.length}: ${scriptSrc}`);
                    await loadScript(scriptSrc);
                }
            })();
        } else {
            // 否则将脚本转换为字符串，并注入
            let strScript = script;
            if (typeof strScript === 'function') {
                strScript = strScript();
            }
            if (!strScript) {
                log.debug(`脚本 ${key} 为空，跳过注入`);
                promise = Promise.resolve();
            } else {
                log.debug(`注入脚本: ${key}`);
                promise = loadScript(strScript, attrs);
            }
        }
        
        promise
            .then(() => {
                log.debug(`脚本 ${key} 注入成功`);
                resolve?.();
            })
            .catch((error) => {
                log.error(`脚本 ${key} 注入失败:`, error);
                reject?.(error);
            });
        
        return promise;
    });

    // 等待所有脚本注入完成
    log.debug('等待所有脚本注入完成');
    return Promise.all(promises);
}