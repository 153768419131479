import { getIsGpViaMeta } from "../biz-meta";
import { getAppEnv, getEditorEnv, getIsGpViaUrlParams } from "../biz-url-params";
import {log} from "../logs";

/**
 * 判断当前环境是否是生产环境
 * 生产环境的特征：
 * 1. 不是localhost
 * 3. 不是IP地址
 * 4. 不是以'-'开头的路径
 *
 * 例如:
 * 生产上线地址为: https://pmtest2.seasungames.cn/index-allen/index.html
 * 开发/预览/预发地址为: https://pmtest2.seasungames.cn/-code/site_buider/silxoh1z141z3n7zactach6o/prod/831ba65c5ddf006837f1255d3fe7daf6/index.html
 */
export const isMasterEnv = (function () {
  const appEnv = getAppEnv();
  if (appEnv !== null) {
    return appEnv === 'prod';
  }
  return !/^\d+\.\d+\.\d+\.\d+$/.test(location.hostname) && !location.pathname.split('/')?.[1]?.startsWith('-');
}());

/**
 * API接口定义
 */
export interface IAPI {
  getPageData: string,
  getLayerData: string
}

/**
 * 是否是GP
 */
export const isGp = getIsGpViaUrlParams() || getIsGpViaMeta();

/**
 * 获取主机名
 * @returns {string} 返回缓存的主机名或新生成的主机名
 */
const getHostname = () => {
  // 缓存变量，用于存储生成的主机名
  let cache = null;

  // 主机名设置，包含GP和CN两种环境的客户端和管理端地址
  const hostnameSettings = {
    gp: {
      client: 'omni-api.seasungames.com',
      manager: 'omni-admin.seasungames.com'
    },
    cn: {
      client: 'omni-api.seasungames.cn',
      manager: 'omni-admin.seasungames.cn'
    }
  }
  
  // const testHostnameSettings = {
  //   gp: {
  //     client: 'omni-api.seasungames.com',
  //     manager: 'omni-admin.seasungames.com'
  //   },
  //   cn: {
  //     client: 'omni-api.seasungames.cn',
  //     manager: 'omni-admin.seasungames.cn'
  //   }
  // }
  //
  
  const editorEnv = getEditorEnv();
  

  return (function () {
    if (cache) {
      return cache;
    }
    
    // const targetHostnameSettings = editorEnv === 'test' ? testHostnameSettings : hostnameSettings;
    // const hostname = targetHostnameSettings[isGp ? 'gp' : 'cn'][isMasterEnv ? 'client' : 'manager'];
    // cache = hostname;
    
    const hostname = hostnameSettings[isGp ? 'gp' : 'cn'][isMasterEnv ? 'client' : 'manager'];
    cache = editorEnv === 'test' ? `test-${hostname}`: hostname;
    
    return cache;
  }())
}

/**
 * 获取当前环境的API操作
 * @returns {IAPI} 返回当前环境的API配置
 */
export const getCurrentApiActions = (): IAPI => {
  const API_ENV: Record<string, IAPI> = {
    prod: {
      getPageData: `https://${getHostname()}/api/cms_api/v1/website/page_data/get_by_page_id`,
      getLayerData: `https://${getHostname()}/api/cms_api/v1/website/page_data/get_by_layer_id`
    },
    qa: {
      getPageData: `https://${getHostname()}/api/cms_manager/v1/website/page_data/get_by_page_id`,
      getLayerData: `https://${getHostname()}/api/cms_manager/v1/website/page_data/get_by_layer_id`
    }
  };

  const appEnv = getAppEnv() || (isMasterEnv ? 'prod' : 'qa');
  log.debug('appEnv', appEnv)
  return API_ENV[appEnv] || API_ENV.prod;
}
