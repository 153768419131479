import {IRequest} from "../request/interface";
import {IErrorReport} from "../error-report/interface";

/**
 * public 公共空间
 */
const varSpace = (window as any)['fl'] = {};

/**
 * 注册公共变量
 * @param name - 公共变量名
 * @param value - 公共变量值
 */
const registerPublicVar = (name: string, value: any): void => {
    (varSpace as any)[name] = value;
}

/**
 * 注册 ready 公共变量
 * @param promise - Promise 对象，用于指示 ready 状态
 */
export const registerReadyPublicVar = (promise: Promise<void>): void => {
    registerPublicVar('ready', promise);
}

/**
 * 注册 request 公共变量
 * @param request - IRequest 对象，用于发送 HTTP 请求
 */
export const registerRequestPublicVar = (request: IRequest): void => {
    registerPublicVar('request', request);
}

/**
 * 注册 sentryErrorReport 公共变量
 * @param errorReport - IErrorReport 对象，用于错误上报
 */
export const registerSentryErrorReportPublicVar = (errorReport: IErrorReport): void => {
    registerPublicVar('errorReport', errorReport);
}