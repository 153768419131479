import ScriptModel from "../_utils/script-model";
import {getBaiduStatisticId} from "../../biz-meta";
import { isSimpleChinese } from "../../langs";

/**
 * 百度 Id
 */
const baiduId = getBaiduStatisticId();

/**
 * 百度统计
 */
export const baiduStatistic = new ScriptModel({
    condition: !!baiduId && isSimpleChinese(),
    script: `//hm.baidu.com/hm.js?${baiduId}`,
    prepare: () => ((window as any)['_hmt'] = window['_hmt'] || [])
});