/**
 * 获取HTML标签的lang属性
 * @returns {string} HTML标签的lang属性值
 */
export function getHtmlLang(): string {
  return document.documentElement.lang || 'en';
}

/**
 * 检查当前HTML语言是否为简体中文
 * @returns {boolean} 如果语言是简体中文则返回true,否则返回false
 */
export function isSimpleChinese(): boolean {
  const lang = getHtmlLang().toLowerCase();
  return lang === 'zh-cn' || lang === 'zh-hans';
}