/**
 * 加载脚本
 * @param src - 脚本地址
 * @param attrs - 脚本属性
 */
export const loadScript = async (
    src: string,
    attrs?: { [key: string]: string }
): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const script: HTMLScriptElement = document.createElement('script')
        script.async = true

        script.onload = () => {
            document.head.removeChild(script)
            resolve()
        }

        script.onerror = (event: Event | string) => reject(event)

        if (attrs) {
            Object.keys(attrs).forEach((key) =>
                script.setAttribute(key, attrs[key])
            )
        }

        script.src = src
        document.head.appendChild(script)
    })
}