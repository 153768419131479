import ScriptModel from "../_utils/script-model";
import { getGoogleAnalyticsId } from "../../biz-meta";
import { isSimpleChinese } from "../../langs";

/**
 * 谷歌统计 id
 */
const gaId = getGoogleAnalyticsId();

/**
 * 谷歌统计
 */
export const gaStatistic = new ScriptModel({
    condition: !!gaId && !isSimpleChinese(),
    script: () => `https://www.googletagmanager.com/gtag/js?id=${gaId}`,
    prepare: () => {
        (window as any).dataLayer = window.dataLayer || [];
        const gtag = function (...args: any[]) {
            (window as any).dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', gaId);
    }
});