import {IAfterResponseData, IBeforeRequestConfig, IRequest} from "./interface";

/**
 * 抽象请求类
 */
export abstract class BaseRequest implements IRequest {

    /**
     * 发送 GET 请求
     * @param url 请求 URL
     * @param params 请求参数
     * @param headers 请求头
     * @returns Promise<T> 响应数据
     */
    abstract get<T>(url: string, params?: any, headers?: any): Promise<T>;

    /**
     * 发送 POST 请求
     * @param url 请求 URL
     * @param data 请求数据
     * @param headers 请求头
     * @returns Promise<T> 响应数据
     */
    abstract post<T>(url: string, data?: any, headers?: any): Promise<T>;

    /**
     * 请求发送前的监听器列表
     */
    protected beforeRequestListeners: ((config: IBeforeRequestConfig) => void)[] = [];

    /**
     * 请求成功后的监听器列表
     */
    protected afterResponseSuccessListeners: ((data: IAfterResponseData) => void)[] = [];

    /**
     * 请求失败后的监听器列表
     */
    protected afterResponseErrorListeners: ((data: IAfterResponseData) => void)[] = [];

    /**
     * 在发送请求之前执行的回调函数
     * @param config 请求配置
     */
    protected callBeforeRequestListeners(config: IBeforeRequestConfig) {
        // 遍历所有的监听器，并执行回调函数
        this.beforeRequestListeners.forEach(listener => listener(config));
    }

    protected callAfterResponseSuccessListeners(data: IAfterResponseData) {
        // 遍历所有的监听器，并执行回调函数
        this.afterResponseSuccessListeners.forEach(listener => listener(data));
    }

    protected callAfterResponseErrorListeners(data: IAfterResponseData) {
        // 遍历所有的监听器，并执行回调函数
        this.afterResponseErrorListeners.forEach(listener => listener(data));
    }

    /**
     * 注册请求发送前的监听器
     * @param callback 回调函数，接受一个 IBeforeRequestConfig 对象作为参数
     */
    registerBeforeRequestListener(callback: (config: IBeforeRequestConfig) => void): void {
        // 将回调函数添加到监听器列表中
        this.beforeRequestListeners.push(callback);
    }

    /**
     * 注册请求成功后的监听器
     * @param callback 回调函数，接受一个 IAfterResponseData 对象作为参数
     */
    registerAfterResponseSuccessListener(callback: (data: IAfterResponseData) => void): void {
        // 将回调函数添加到监听器列表中
        this.afterResponseSuccessListeners.push(callback);
    }

    /**
     * 注册请求失败后的监听器
     * @param callback 回调函数，接受一个 IAfterResponseData 对象作为参数
     */
    registerAfterResponseErrorListener(callback: (data: IAfterResponseData) => void): void {
        // 将回调函数添加到监听器列表中
        this.afterResponseErrorListeners.push(callback);
    }
}