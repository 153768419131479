/**
 * 一个空函数，用于占位或忽略返回值。
 */
export function noop(): void {
}

/**
 * 检查一个值是否与指定的类型匹配。
 * @param value - 要检查的值。
 * @param typeName - 要比较的类型名称。
 * @returns 如果值与指定的类型匹配，则返回 true，否则返回 false。
 */
export function isTypeMatch(value: any, typeName: string): boolean {
    return Object.prototype.toString.call(value) === `[object ${typeName}]`;
}

/**
 * 检查一个值是否为数组。
 * @param value - 要检查的值。
 * @returns 如果值是数组，则返回 true，否则返回 false。
 */
export function isArray(value: any): boolean {
    return isTypeMatch(value, 'Array');
}