import ScriptModel from "../_utils/script-model";
import {
    getSentryDsn,
    getSentryEnvironment,
    getSentryRelease, getSentryReplaysOnErrorSampleRate,
    getSentryReplaysSessionSampleRate,
} from "../../biz-meta";
import {registerSentryErrorReportPublicVar} from "../../utils/register-public-var";
import errorReport from "../../error-report";

/**
 * 获取 Sentry 的 DSN
 */
const dsn = getSentryDsn();

/**
 * 错误上报
 */
export const errorReportSM = new ScriptModel({
    condition: !!dsn,
    prepare: () => {
        errorReport.initialize(() => {
            return {
                dsn,
                environment: getSentryEnvironment(),
                release: getSentryRelease(),
                replaysSessionSampleRate: getSentryReplaysSessionSampleRate(),
                replaysOnErrorSampleRate: getSentryReplaysOnErrorSampleRate()
            }
        });
        registerSentryErrorReportPublicVar(errorReport);
    }
});