interface CmsFormItemValueAdaptor<TCmsValue, TTemplateValue> {
  
  valueType: string;
  
  toTemplateValue(cmsValue: TCmsValue): TTemplateValue;
}


type ICmsUploadValue = Array<{
  id: string,
  name: string,
  uid: string,
  url: string
}>

type ICmsLinkValue = {
  type: string,
  url: string
}

type ITemplateStringValue = string;

export class CmsImageValueAdaptor implements CmsFormItemValueAdaptor<ICmsUploadValue, ITemplateStringValue> {
  valueType = 'image';
  
  toTemplateValue(cmsValue: ICmsUploadValue) {
    return cmsValue?.[0]?.url;
  }
}

export class CmsVideoValueAdaptor implements CmsFormItemValueAdaptor<ICmsUploadValue, ITemplateStringValue> {
  valueType = 'video';
  
  toTemplateValue(cmsValue: ICmsUploadValue) {
    return cmsValue?.[0]?.url;
  }
}

export class CmsFileValueAdaptor implements CmsFormItemValueAdaptor<ICmsUploadValue, ITemplateStringValue> {
  valueType = 'file';
  
  toTemplateValue(cmsValue: ICmsUploadValue) {
    return cmsValue?.[0]?.url;
  }
}

export class CmsLinkValueAdaptor implements CmsFormItemValueAdaptor<ICmsLinkValue, ITemplateStringValue> {
  valueType = 'link';
  
  toTemplateValue(cmsValue: ICmsLinkValue) {
    return cmsValue?.url;
  }
}

export const cmsFormItemValueAdaptors: CmsFormItemValueAdaptor<any, any>[] = [new CmsImageValueAdaptor(), new CmsVideoValueAdaptor(), new CmsFileValueAdaptor(), new CmsLinkValueAdaptor()]
