import ScriptModel from "../_utils/script-model";
import { getStReportConfig } from "../../biz-meta";
import { isSimpleChinese } from "../../langs";
import { isMasterEnv } from "../../constants";

/**
 * st-report 统计
 * @description 用于国内埋点上报
 */
export const stReport = new ScriptModel({
    condition: isSimpleChinese() && isMasterEnv,
    script: `https://zhcdn01.xoyo.com/xassets/lib/st-report-sdk/0.2.x/st-report-sdk.min.js`,
    resolve: () => {
        const stReportConfig = getStReportConfig();
        if (stReportConfig) {
            const stReport = (window as any).StReportSdk.getInstance(stReportConfig);
            stReport.trackPageLoad();
            stReport.trackPageLeave();
        }
    }
});