// 导入必要的模块和类型
import "./typing";
import {scriptsInjecting} from "./scripts-injecting";
import {registerReadyPublicVar, registerRequestPublicVar} from "./utils/register-public-var";
import request from "./request";
import {FunLinkSdk} from "./fun-link-sdk";

/**
 * 启动FunLink SDK的异步函数
 */
const startFunLink = async () => {
    // 注册请求公共变量
    registerRequestPublicVar(request);
    
    // 创建FunLinkSdk实例并启动
    new FunLinkSdk().bootstrap();
    
    // 注入脚本
    await scriptsInjecting();
}

// 注册就绪公共变量,并立即执行startFunLink函数
registerReadyPublicVar(startFunLink());
