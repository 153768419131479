import axios from "axios";
import { merge } from './object-mapper';
import CmsDataAdaptor from "./cms-data-adaptor";
import { getCurrentApiActions, IAPI } from "./constants";
import { getAutoFetch, getPageId } from "./biz-meta";
import { log } from "./logs";

/**
 * 配置文件
 */
export interface FunLinkSdkConfig {
  /**
   * 页面 id
   */
  pageId: string | null | undefined,
  /**
   * 入口 api
   */
  appEnv: string | null | undefined;
  /**
   * 禁用自动请求数据
   */
  autoFetch: boolean;
}


/**
 * common 通用 layer
 * pagination 分页数据 layer
 * content 内容 layer
 */
export type LayerType = 'common' | 'pagination' | 'content';


export interface PaginationLayerDataOption {
  perPage: number,
  page: number
}

export interface ContentLayerDataOption {
  contentId: string
}

export type LayerDataOption = {
  layerId: string,
  type: 'pagination',
  param: PaginationLayerDataOption
} | {
  layerId: string,
  type: 'content',
  param: ContentLayerDataOption
}

/**
 * layer 类型
 */
const LAYER_TYPE = {
  //  通用 layer
  common: 'common',
  //  分页数据 layer
  pagination: 'pagination',
  // 内容 layer
  content: 'content'
} as const;


/**
 * 低代码连接器
 */
export class FunLinkSdk {
  apiActions: IAPI;

  private validateSdkConfig() {
    const pageId = getPageId();
    if (!pageId) {
      throw new Error('[fun link sdk]: pageId 缺失');
    }
  }

  private wrapResStruct(data: any) {
    return {
      status: 0,
      data: data,
      msg: ''
    }
  }

  constructor() {
    window.FUN_LINK_SDK = this;
    this.validateSdkConfig();
    this.apiActions = getCurrentApiActions();
  }

  /**
   * 启动入口
   */
  public bootstrap() {
    const autoFetch = getAutoFetch();
    if (autoFetch) {
      window[this.getGlobalPromiseName()] = this.getPageData();
    }
  }

  /**
   * 请求获取页面数据
   */
  public async getPageData(layerDataOptions?: LayerDataOption[]) {
    const pageId = getPageId();
    const res = await axios.post(this.apiActions.getPageData, {
      pageId,
      layers: layerDataOptions
    }).then(res => res.data);
    if (res?.status !== 0) {
      throw res;
    }
    const pageData = res.data;
    return this.wrapResStruct(this.getDestinationPageData(pageData?.layers));
  }

  /**
   * 获取 getLayersData
   * @param layerDataOptions
   */
  public async getLayersData(layerDataOptions: LayerDataOption[]) {
    const pageId = getPageId();
    return axios.post(this.apiActions.getLayerData, {
      pageId: pageId,
      layers: layerDataOptions
    }).then(res => {
      if (res?.status !== 0) {
        throw res;
      }
      const layersData = res.data;
      return this.wrapResStruct(this.getDestinationLayersData(layersData.layers));
    });
  }

  /**
   * 获取映射前的数据
   * @param layer
   * @param layerType
   * @param mapper
   * @private
   */
  private getMapperSourceData(layer: any, layerType: LayerType, mapper?: {
    datasourceId: string,
    positionId: string,
  }) {
    if (layerType === LAYER_TYPE.pagination || layerType === LAYER_TYPE.content) {
      return layer.data;
    }
    const positionData = layer.positions.find((position: any) => position.positionId === mapper?.positionId);
    return (positionData.datasources || []).find((datasource: any) => datasource.datasourceId === mapper?.datasourceId)?.data || {};
  }

  /**
   * 将layer数据进行数据映射转换为目标数据
   * @param layer
   * @param layerType
   * @private
   */
  private getDestinationLayerData(layer: any, layerType: LayerType = LAYER_TYPE.common) {
    let layerDestinationData = {};
    const variableBindData = layer.variableBinds || [];
    variableBindData.forEach((variableBindItem: any) => {
      if (variableBindItem.key && variableBindItem.mapper && variableBindItem.mapper.positionId && variableBindItem.mapper.datasourceId && variableBindItem.mapper.key) {
        const mapperSourceData = this.getMapperSourceData(layer, layerType, variableBindItem.mapper);
        const mapperObject = {
          // [`${Array.isArray(mapperSourceData) ? '[].' : ''}${variableBindItem.mapper.key}`]: variableBindItem.key
          [`${Array.isArray(mapperSourceData) ? '[].' : ''}${variableBindItem.mapper.key}`]: {
            key: variableBindItem.key,
            transform: (value: any) => {
              return CmsDataAdaptor.adapterToTemplateData(variableBindItem.mapper.valueType, value)
            }
          }
        }
        merge(mapperSourceData, layerDestinationData, mapperObject);
      }
    })
    return layerDestinationData;
  }

  /**
   * 锅炉获取顶层layer数据
   * @param layers
   * @private
   */
  private filterTopLayerData(layers: any[] = []) {
    return layers.filter(layer => layer.topLayer === true || (layer.layerId || '').split('.').length === 1);
  }

  /**
   * 将页面数据进行数据映射转换为目标数据
   * @param layers
   * @private
   */
  private getDestinationPageData(layers: any[] = []) {
    let pageDestinationData = {};
    this.filterTopLayerData((layers || [])).forEach(layer => {
      const layerData = this.getDestinationLayerData(layer);
      pageDestinationData = {
        ...pageDestinationData,
        ...(layerData || {})
      }
    })
    log.debug('pageDestinationData', pageDestinationData);
    return pageDestinationData;
  }
  
  /**
   * 将多个layer进行数据映射转换为目标数据
   * @param layers
   * @private
   */
  private getDestinationLayersData(layers: any[] = []) {
    let layersDestinationData = {};
    layers.forEach(layer => {
      const layerData = this.getDestinationLayerData(layer);
      layersDestinationData = {
        ...layersDestinationData,
        ...(layerData || {})
      }
    })
    log.debug('layersDestinationData', layersDestinationData);
    return layersDestinationData;
  }
  
  
  /**
   * 获取 html 中 meta attribute
   * @param attributeName
   * @private
   */
  private getAttributeFormHtml(attributeName: string) {
    return (document.querySelector(`meta[name=${attributeName}]`) as HTMLMetaElement)?.content;
  }

  /**
   * 获取 cms 请求 promise 名称
   * @private
   */
  private getGlobalPromiseName() {
    const attributeName = 'data-fun-link-global-promise-name';
    return this.getAttributeFormHtml(attributeName) || '__FUN_LINK_PAGE_DATA_PROMISE__';
  }
}
