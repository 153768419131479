import { getDebugMode } from "../biz-meta";
import { getDebugModeViaUrlParams } from "../biz-url-params";

/**
 * 日志类
 * @description 用于记录日志信息的类
 */
class Log {
    /**
     * 构造函数
     * @param isDebugEnabled - 是否开启调试模式
     * @param namespace - 日志命名空间
     */
    constructor(private isDebugEnabled: boolean, private namespace: string = '') { }

    /**
     * 格式化日志消息
     * @param message - 日志消息
     * @returns 格式化后的日志消息
     */
    private formatMessage(message: any): string {
        return `[${this.namespace}] ${message}`;
    }

    /**
     * 记录普通日志
     * @param message - 日志消息
     * @param optionalParams - 可选参数
     */
    log(message?: any, ...optionalParams: any[]) {
        if (this.isDebugEnabled) {
            console.log(this.formatMessage(message), ...optionalParams);
        }
    }

    /**
     * 记录警告日志
     * @param message - 警告消息
     * @param optionalParams - 可选参数
     */
    warn(message?: any, ...optionalParams: any[]) {
        if (this.isDebugEnabled) {
            console.warn(this.formatMessage(message), ...optionalParams);
        }
    }

    /**
     * 记录错误日志
     * @param message - 错误消息
     * @param optionalParams - 可选参数
     */
    error(message?: any, ...optionalParams: any[]) {
        if (this.isDebugEnabled) {
            console.error(this.formatMessage(message), ...optionalParams);
        }
    }

    /**
     * 记录信息日志
     * @param message - 信息消息
     * @param optionalParams - 可选参数
     */
    info(message?: any, ...optionalParams: any[]) {
        if (this.isDebugEnabled) {
            console.info(this.formatMessage(message), ...optionalParams);
        }
    }

    /**
     * 记录调试日志
     * @param message - 调试消息
     * @param optionalParams - 可选参数
     */
    debug(message?: any, ...optionalParams: any[]) {
        if (this.isDebugEnabled) {
            console.debug(this.formatMessage(message), ...optionalParams);
        }
    }
}

const log = new Log(getDebugMode() || getDebugModeViaUrlParams(), 'funlink');
export { log };
