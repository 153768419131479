import { getHtmlLang } from "../langs";
import { queryMeta } from "../utils/query-meta";
import {getQueryValue} from "../biz-url-params";

/**
 * 获取 Google Analytics 的 ID, e.g. UA-XXXXXXXX-1
 * @returns {string | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getGoogleAnalyticsId(): string | undefined {
    return queryMeta('ga-id') || undefined;
}

/**
 * 获取百度统计的 ID, e.g. 12345678
 * @returns {string | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getBaiduStatisticId(): string | undefined {
    return queryMeta('baidu-id') || undefined;
}

/**
 * 获取 Sentry 的 DSN, e.g. 080e660fa07b3003d2c83755d5261fb0
 * @returns {string | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getSentryDsn(): string | undefined {
    // 获取 Sentry 的 DSN
    return queryMeta('sentry-dsn') || undefined;
}

/**
 * 获取 Sentry 的环境, e.g. prod / test
 * @returns {string | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getSentryEnvironment(): string | undefined {
    // 获取 Sentry 的环境
    return queryMeta('sentry-environment') || undefined;
}

/**
 * 获取 Sentry 的版本, e.g. 1.0.0
 * @returns {string | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getSentryRelease(): string | undefined {
    // 获取 Sentry 的版本
    return queryMeta('sentry-release') || undefined;
}

/**
 * 获取 Sentry 的会话重放采样率, e.g. 0.1
 * @returns {number | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getSentryReplaysSessionSampleRate(): number | undefined {
    // 获取 Sentry 的会话重放采样率
    return Number(queryMeta('sentry-replays-session-sample-rate')) || undefined;
}

/**
 * 获取 Sentry 的错误重放采样率, e.g. 1
 * @returns {number | null} 如果找到了对应的 meta 标签，则返回它的 content 属性值，否则返回 undefined
 */
export function getSentryReplaysOnErrorSampleRate(): number | undefined {
    // 获取 Sentry 的错误重放采样率
    return Number(queryMeta('sentry-replays-on-error-sample-rate')) || undefined;
}

/**
 * 获取是否为全局页面（GP）
 * @returns {number} 如果是全局页面返回1，否则返回0
 */
export function getIsGpViaMeta(): boolean {
    const isGp = queryMeta('gp');
    return isGp === '1' || isGp === 'true';
}

/**
 * ST报告配置接口
 */
export interface IStReportConfig {
    /** 项目标识符 */
    projectIdentifier: string | null;
    /** 事件组 */
    eventGroup: string | null;
    /** 事件标签数组，如果存在则按逗号分割 */
    eventTags: string[] | null;
}

/**
 * 获取ST报告配置
 * @returns 包含项目标识符、事件组和事件标签的对象，如果所有元素都存在则返回完整配置，否则返回null
 */
export function getStReportConfig(): IStReportConfig | null {
    const stReportConfigJsonString = queryMeta('st-report-config');

    const stReportConfig = stReportConfigJsonString ? JSON.parse(stReportConfigJsonString) : null;

    if (!stReportConfig) {
        return null;
    }

    const projectIdentifier = stReportConfig.projectIdentifier;
    const eventGroup = stReportConfig.eventGroup;
    const eventTags = stReportConfig.eventTags;

    return {
        projectIdentifier,
        eventGroup,
        eventTags
    };
}

/**
 * 海外报告配置接口
 */
export interface IOverseaReportConfig {
    /** 应用ID */
    appId: string | null;
    /** 应用版本 */
    appVersion: string | null;
    /** 渠道 */
    channel: string | null;
    /** 语言 */
    language: string | null;
}

/**
 * 获取海外报告配置
 * @returns {IOverseaReportConfig | null} 包含项目标识符、事件组和事件标签的对象，如果所有元素都存在则返回完整配置，否则返回null
 */
export function getOverseaReportConfig(): IOverseaReportConfig | null {
    const overseaReportConfigJsonString = queryMeta('oversea-report-config');

    const overseaReportConfig = overseaReportConfigJsonString ? JSON.parse(overseaReportConfigJsonString) : null;

    if (!overseaReportConfig) {
        return null;
    }

    const defaultConfig = {
        appVersion: '1.0',
        language: getHtmlLang(),
    };

    return Object.assign(defaultConfig, overseaReportConfig);
}

/**
 * 获取调试模式状态
 * @returns {boolean} 是否处于调试模式
 */
export function getDebugMode(): boolean {
    const debugMode = queryMeta('funlink-debug-mode');
    return debugMode === '1' || debugMode === 'true';
}


/**
 * 获取页面ID
 * @returns {string | null} 如果找到了对应的meta标签，则返回其值，否则返回null
 */
export const getPageId: () => string | null = (function () {
    let pageId: string | null;
    return () => {
        if (pageId === undefined) {
            pageId = queryMeta('pageId') || getQueryValue('pageId') || null;
        }
        return pageId;
    }
}());

/**
 * 获取是否自动获取数据的标志
 * @returns {boolean} 是否自动获取数据
 */
export const getAutoFetch: () => boolean = (function () {
    let autoFetch: boolean;
    return () => {
        if (autoFetch === undefined) {
            const autoFetchQuery = queryMeta('autoFetch') || getQueryValue('autoFetch');
            autoFetch = autoFetchQuery !== 'false' && autoFetchQuery !== '0';
        }
        return autoFetch;
    }
}());

